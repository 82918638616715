import React from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'gatsby-plugin-intl';

import Layout from 'src/components/Layout';
import SEO from 'src/components/SEO';
import Header from 'src/components/Header';
import { useStaticQuery, graphql } from 'gatsby';
import { Heading, Flex, Box } from 'rebass/styled-components';
import { FormattedMessage } from 'gatsby-plugin-intl';
import Img from 'gatsby-image/withIEPolyfill';
import ShowCaseCard from 'src/components/ShowCaseCard';
import Footer from 'src/sections/Footer';

const centerHorizontally = { marginRight: 'auto', marginLeft: 'auto' };

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

const Complex = () => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      chalandriA: allFile(
        sort: { order: ASC, fields: [base] }
        filter: { extension: { regex: "/(jpg)|(png)|(jpeg)/" }, relativeDirectory: { eq: "properties/chalandri-a" } }
      ) {
        edges {
          node {
            base
            ...fluidImage
          }
        }
      }
      building1: allFile(
        sort: { order: ASC, fields: [base] }
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "properties/chalandri-a/building-1-center" }
        }
      ) {
        edges {
          node {
            base
            ...fluidImage
          }
        }
      }
      building2: allFile(
        sort: { order: ASC, fields: [base] }
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "properties/chalandri-a/building-2-left" }
        }
      ) {
        edges {
          node {
            base
            ...fluidImage
          }
        }
      }
      building3: allFile(
        sort: { order: ASC, fields: [base] }
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "properties/chalandri-a/building-3-right" }
        }
      ) {
        edges {
          node {
            base
            ...fluidImage
          }
        }
      }
    }
  `);

  const building1Images = data.building1.edges.map(image => {
    return {
      src: image.node.childImageSharp.fluid.src,
      thumbnail: image.node.childImageSharp.fluid.src,
      caption: intl.formatMessage({ id: 'projects.building1' }),
    };
  });

  const building2Images = data.building2.edges.map(image => {
    return {
      src: image.node.childImageSharp.fluid.src,
      thumbnail: image.node.childImageSharp.fluid.src,
      caption: intl.formatMessage({ id: 'projects.building2' }),
    };
  });

  const building3Images = data.building3.edges.map(image => {
    return {
      src: image.node.childImageSharp.fluid.src,
      thumbnail: image.node.childImageSharp.fluid.src,
      caption: intl.formatMessage({ id: 'projects.building3' }),
    };
  });

  return (
    <Layout
      style={{
        // backgroundColor: "#e8e8e8",
        minHeight: '100vh',
      }}
    >
      <SEO title="Paisios Constructions | Projects" />
      <Helmet />
      <Header />

      <Box
        sx={{
          maxWidth: 960,
          mx: 'auto',
        }}
      >
        <Heading
          as="h2"
          color="primary"
          fontSize={[4, 5]}
          pt={150}
          mb={5}
          textAlign="center"
          style={centerHorizontally}
        >
          <FormattedMessage id="projects.chalandriA" />
        </Heading>

        <Img
          fluid={data.chalandriA.edges[0].node.childImageSharp.fluid}
          alt={intl.formatMessage({ id: 'projects.chalandriA' })}
        />

        <Flex flexWrap="wrap" mt={5} justifyContent="center">
          <ShowCaseCard
            cardImage={data.building1.edges[0].node.childImageSharp.fluid}
            projectImages={building1Images}
            headingText={intl.formatMessage({ id: 'projects.building1' })}
          ></ShowCaseCard>
          <ShowCaseCard
            cardImage={data.building2.edges[0].node.childImageSharp.fluid}
            projectImages={building2Images}
            headingText={intl.formatMessage({ id: 'projects.building2' })}
          ></ShowCaseCard>
          <ShowCaseCard
            cardImage={data.building3.edges[0].node.childImageSharp.fluid}
            projectImages={building3Images}
            headingText={intl.formatMessage({ id: 'projects.building3' })}
          ></ShowCaseCard>
        </Flex>
      </Box>
      <Footer />
    </Layout>
  );
};

export default Complex;
